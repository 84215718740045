var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "user-row",
      class: { pending: _vm.isPending },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("div", { staticClass: "user-row-name" }, [_vm._v(_vm._s(_vm.name))]),
      _c("div", { staticClass: "user-row-email" }, [_vm._v(_vm._s(_vm.email))]),
      _c(
        "div",
        { staticClass: "user-row-status" },
        [
          _vm.isPending
            ? _c("el-tag", { attrs: { type: "danger" } }, [_vm._v("Pending")])
            : _c("el-tag", { attrs: { type: "info" } }, [_vm._v("Active")]),
        ],
        1
      ),
      _c("div", { staticClass: "user-row-users" }, [
        _vm._v(_vm._s(_vm.userCount)),
      ]),
      _vm.can_delete
        ? _c(
            "div",
            { staticClass: "user-row-delete" },
            [
              _vm.userCount === 0
                ? _c(
                    "el-popconfirm",
                    {
                      attrs: {
                        title: "Are you sure you want to delete this user?",
                        icon: "el-icon-warning",
                        "icon-color": "red",
                        "confirm-button-text": "Delete",
                        "cancel-button-text": "Cancel",
                      },
                      on: { confirm: _vm.handleDeleteUser },
                    },
                    [
                      _c("el-button", {
                        attrs: {
                          slot: "reference",
                          type: "danger",
                          icon: _vm.deleting
                            ? "el-icon-loading"
                            : "el-icon-delete",
                          size: "mini",
                          disabled: _vm.deleting,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                        slot: "reference",
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }