<template>
    <div class="user-row" :class="{pending: isPending}" @click="$emit('click')">
        <div class="user-row-name">{{ name }}</div>

        <div class="user-row-email">{{ email }}</div>

        <div class="user-row-status">
            <el-tag v-if="isPending" type="danger">Pending</el-tag>
            <el-tag v-else type="info">Active</el-tag>
        </div>

        <div class="user-row-users">{{ userCount }}</div>

        <div v-if="can_delete" class="user-row-delete">
            <el-popconfirm
                v-if="userCount === 0"
                title="Are you sure you want to delete this user?"
                icon="el-icon-warning"
                icon-color="red"
                confirm-button-text="Delete"
                cancel-button-text="Cancel"
                @confirm="handleDeleteUser"
            >
                <el-button
                    slot="reference"
                    type="danger"
                    :icon="deleting ? 'el-icon-loading' : 'el-icon-delete'"
                    size="mini"
                    :disabled="deleting"
                    @click.stop
                />
            </el-popconfirm>
        </div>
    </div>
</template>

<script>
export default {
    name: 'external-user-row',
    props: {
        user: {
            type: Object,
            required: true,
        },
        can_delete: Boolean,
    },
    data() {
        return {
            deleting: false,
        };
    },
    computed: {
        isPending() {
            return this.user && this.user.status === 'pending';
        },
        name() {
            if (this.isPending) return 'PENDING';
            return this.user.name;
        },
        email() {
            return this.user.email;
        },
        userCount() {
            if (this.user && this.user.managedUsers) {
                return this.user.managedUsers.length;
            }
            return 0;
        },
    },
    methods: {
        async handleDeleteUser() {
            this.deleting = true;
            this.$emit('delete', this.email);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-row {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f2f5;
    background-color: white;
    transition: background-color 0.125s linear;
    cursor: pointer;
    &:hover {
        background-color: #f5f7fa;
    }

    > * {
        &:first-child {
            flex-grow: 1;
        }

        display: flex;
        align-items: center;
        border-right: 1px solid #f0f2f5;
        height: 100%;
        min-width: 100px;
        padding: 10px;
        color: #606266;
        font-size: 14px;
    }

    .user-row-name,
    .user-row-email {
        flex: 1;
    }

    .user-row-status,
    .user-row-users {
        width: 100px;
        justify-content: center;
    }

    .user-row-delete {
        width: 80px;
        justify-content: center;
        padding: 0;
        min-width: 0;
    }

    &.pending {
        .user-row-name {
            color: #c0c0c0;
        }
    }
}

.el-tag {
    width: 100%;
    text-align: center;
}
</style>
