<template>
    <div class="app-container">
        <div class="filter-container">
            <el-input
                v-model="search"
                style="flex: 1"
                class="item"
                :placeholder="'Search external user'"
            />
        </div>

        <external-user-list
            v-loading="loading"
            element-loading-text="Loading users"
            :users="filteredUsers"
            :scroll="true"
            :css="{height: 'calc(100vh - 200px)'}"
            :can_delete="can_delete"
            @delete-user="handleDeleteUser"
        />
    </div>
</template>

<script>
import ExternalUserList from '@/views/components/ExternalUserList';
import auth from '@/utils/auth';
import axios from 'axios';

export default {
    name: 'users',
    components: {
        ExternalUserList,
    },
    props: {
        view: {
            type: String,
            default: null,
        },
        initial_search: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            users: [],
            loading: true,
            search: '',
            debouncedSearch: '',
        };
    },
    computed: {
        filteredUsers() {
            if (!this.users) return [];
            let users = this.users;
            if (this.search) {
                users = this.users.filter((u) => {
                    if (u.name) {
                        return u.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase());
                    }
                    return u.email
                        .toLowerCase()
                        .includes(this.search.toLowerCase());
                });
            }
            return users.sort((a, b) => {
                if (!a.name) {
                    if (!b.name) {
                        // if neither user is named, sort by email
                        return a.email.localeCompare(b.email);
                    }
                    // named users before pending users
                    return 1;
                }
                if (!b.name) {
                    // named users before pending users
                    return -1;
                }
                // sort by name
                return a.name.localeCompare(b.name);
            });
        },
        can_delete() {
            return auth.role === auth.ROLE_SUPERADMIN;
        },
    },
    mounted() {
        this.search = this.initial_search;
        this.bindUsers();
    },

    methods: {
        bindUsers() {
            this.loading = true;

            this.$bind(
                'users',
                this.$fire
                    .collection('users')
                    .where('role', '==', auth.ROLE_EXTERNAL),
                {
                    maxRefDepth: 0,
                }
            );

            this.loading = false;
        },

        async handleDeleteUser(user) {
            try {
                await this.deleteUser(user);
            } catch (err) {
                this.$notify.error({
                    title: 'Error',
                    message: 'Error deleting user',
                });
            }
            // force refresh of list
            this.bindUsers();
        },

        deleteUser(email) {
            return axios.delete(
                `${process.env.VUE_APP_BASE_URI}/user/delete-ext`,
                {
                    data: {email},
                }
            );
        },
    },
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.pagination-container {
    background-color: white;
    margin: 0;
    padding: 20px;
    border: 1px solid #ebeef5;
    border-top: 0;
    display: flex;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.full-width {
    width: 100%;
}

.el-tag {
    width: 100%;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 13px;
    font-weight: 500;
}
</style>
