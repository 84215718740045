var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "item",
            staticStyle: { flex: "1" },
            attrs: { placeholder: "Search external user" },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _c("external-user-list", {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading",
          },
        ],
        attrs: {
          "element-loading-text": "Loading users",
          users: _vm.filteredUsers,
          scroll: true,
          css: { height: "calc(100vh - 200px)" },
          can_delete: _vm.can_delete,
        },
        on: { "delete-user": _vm.handleDeleteUser },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }