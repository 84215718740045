<template>
    <div class="user-list-wrapper">
        <div class="user-list" :class="{scroll}" :style="css">
            <div class="header">
                <div class="header-col name">Name</div>
                <div class="header-col email">Email</div>
                <div class="header-col status">Status</div>
                <div class="header-col users">Managing</div>
                <div v-if="can_delete" class="header-col delete">
                    <i class="el-icon-delete" />
                </div>
            </div>
            <div v-if="users.length" class="user-list-body">
                <external-user-row
                    v-for="user in users"
                    :key="user.id"
                    :user="user"
                    :can_delete="can_delete"
                    :show_expired="show_expired"
                    :show_details="show_details"
                    @click="$router.push(`/external/${user.id}`)"
                    @delete="deleteUser"
                />
            </div>
            <div v-else class="no-users">No data</div>
        </div>

        <div v-if="scroll" class="user-list-footer">
            {{ users.length | pluralize(' user') }} found
        </div>
        <div v-else class="page-nav">
            <el-button
                icon="el-icon-arrow-left"
                circle
                @click="$emit('prev')"
            />
            <el-button
                icon="el-icon-arrow-right"
                circle
                @click="$emit('next')"
            />
        </div>
    </div>
</template>

<script>
import ExternalUserRow from '@/views/components/ExternalUserRow';
import axios from 'axios';

export default {
    name: 'external-user-list',
    components: {
        ExternalUserRow,
    },
    props: {
        users: {
            type: Array,
            required: true,
        },
        nav_content: {
            type: String,
            default: null,
        },
        show_details: {
            type: Boolean,
            default: true,
        },
        plan_heading: {
            type: String,
            default: 'Active plan',
        },
        show_expired: {
            type: Boolean,
            default: false,
        },
        scroll: Boolean,
        css: {
            type: Object,
            required: false,
            default: null,
        },
        can_delete: Boolean,
    },
    methods: {
        deleteUser(user) {
            this.$emit('delete-user', user);
        },
    },
};
</script>

<style lang="scss" scoped>
.user-list {
    background: white;
    min-height: 300px;

    .header {
        display: flex;
        width: 100%;
        height: 50px;
        border-bottom: 1px solid #f0f2f5;

        .header-col {
            display: flex;
            align-items: center;
            border-right: 1px solid #f0f2f5;
            padding: 10px;
            font-size: 14px;
            color: #909399;
            font-weight: 600;

            &.name {
                flex-grow: 1;
            }
            &.email {
                flex-grow: 1;
            }

            &.status,
            &.users {
                width: 100px;
                justify-content: center;
            }
            &.delete {
                width: 80px;
                justify-content: center;
            }
        }
    }

    &.scroll {
        position: relative;
        .user-list-body {
            overflow-y: auto;
            height: calc(100% - 50px);
            padding-bottom: 50px;
            position: relative;
        }
        &::after {
            content: '';
            height: 80px;
            width: 100%;
            pointer-events: none;
            background: linear-gradient(
                0deg,
                rgba(255, 255, 255, 1) 0%,
                rgba(255, 255, 255, 0) 100%
            );
            position: absolute;
            bottom: 0;
        }
    }
}

.user-list-footer {
    opacity: 0.75;
    margin-top: 15px;
    font-size: 14px;
    text-align: center;
}

.page-nav {
    padding: 10px;
    text-align: center;
    .nav-content {
        display: inline-block;
        color: $blue;
        padding: 0 10px;
        min-width: 80px;
    }
}

.no-users {
    width: 100%;
    line-height: 50px;
    color: #606266;
    text-align: center;
}
</style>
